/* eslint no-console:0 */

// MODULES =====================================================================
import Rails from 'rails-ujs'
import "bootstrap"
import "@appPages/vue"

import "stylesheets/application.sass"
import ImageObjectFit from "@utils/image-object-fit"
import FlashMessage from "@utils/flash-messages"
import ImageLoad from "@utils/image-load"

// Fonts ===============================================================
import "typeface-roboto"
import "typeface-roboto-condensed"

// CONFIGURATION ===============================================================
Rails.start()

window.FlashMessage = FlashMessage

window.recaptachCallback = () => {
  document.getElementById('new_contact').submit()
}

document.addEventListener("DOMContentLoaded", () => {
  new ImageObjectFit()
  new ImageLoad()
})
