<template lang="pug">
  div
    p {{ message }}
</template>

<script>
export default {
  data () {
    return {
      message: "Hello Vue from application!"
    }
  }
}
</script>

<style scoped>
p {
  font-size: 2em;
  text-align: center;
}
</style>
