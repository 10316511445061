import Vue from 'vue'
import Transitions from 'vue2-transitions'
import VueColcade from 'vue-colcade'
import VueSimpleLightbox from 'vue-simple-lightbox'
import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faChevronLeft,
  faLongArrowRight
} from '@fortawesome/pro-light-svg-icons'
import {
  faMapMarkerAlt,
  faEnvelope,
  faPhone
} from '@fortawesome/pro-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import PrettyCheck from 'pretty-checkbox-vue/check'
import VueMq from 'vue-mq'

/**
 * Automatically register components in the "pages/application" folder.
*/
const files = require.context('./', true, /\.vue$/i)
files.keys().map(key => {
  const component = key.split('/').pop().split('.')[0]

  Vue.component(component, () => import(`./${component}`))
})

// Components
Vue.component('Slick', () => import('@components/Slick'))
Vue.component('ActiveItem', () => import('@components/ActiveItem'))
Vue.component('Grid', () => import('@components/Grid'))
Vue.component('vue-simple-lightbox', VueSimpleLightbox)
Vue.component('font-awesome-icon', FontAwesomeIcon)
Vue.component('p-check', PrettyCheck)

Vue.use(Transitions)
Vue.use(VueColcade)

Vue.use(VueMq, {
  breakpoints: { // default breakpoints - customize this
    sm: 576,
    md: 768,
    lg: 992,
    xl: Infinity
  }
})

library.add(
  faChevronLeft,
  faLongArrowRight,
  faMapMarkerAlt,
  faEnvelope,
  faPhone
)

document.addEventListener('DOMContentLoaded', () => {
  new Vue({
    el: '#vue-app'
  })
})
